import StatPicker from "./StatPicker";

const StatPickers = ({ statNames }) => (
  <div className="stat-pickers">
    {statNames.map((s, i) => (
      <StatPicker key={i} name={s} />
    ))}
  </div>
);

export default StatPickers;
