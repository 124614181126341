import { createContext, useState } from "react";
import {
  statNames,
  statCosts,
  standardType,
  minStatsByType,
  maxStatsByType,
  startPointsByType,
} from "./constants";

export const StatsContext = createContext(null);

const statDefaults = statNames.reduce(
  (stats, curr) => {
    stats[curr] = 10;
    return stats;
  },
  { points: startPointsByType[standardType], generation: standardType }
);

const statsStorageKey = "stats";

export const StatsProvider = ({ children }) => {
  const [stats, setStats] = useState(() => {
    const storedStatsString = localStorage.getItem(statsStorageKey);
    if (storedStatsString == null) {
      return statDefaults;
    }
    const storedStats = JSON.parse(storedStatsString);
    return { ...statDefaults, ...storedStats };
  });

  const setStatsWithSave = (newStats) => {
    setStats(newStats);
    localStorage.setItem(statsStorageKey, JSON.stringify(newStats));
  };

  const setStat = (name, value) => {
    if (stats[name] === value) {
      return;
    }
    const newStats = { ...stats, [name]: value };
    newStats.points = statNames.reduce(
      (points, stat) => points - statCosts[newStats[stat]],
      startPointsByType[stats.generation]
    );
    setStatsWithSave(newStats);
  };

  const resetStats = (generation = null) => {
    if (generation === stats.generation) {
      return;
    }
    generation ??= stats.generation;
    setStatsWithSave({
      ...statDefaults,
      points: startPointsByType[generation],
      generation,
    });
  };

  return (
    <StatsContext.Provider
      value={{
        stats,
        setStat,
        resetStats,
        minStat: minStatsByType[stats.generation],
        maxStat: maxStatsByType[stats.generation],
        startPoints: startPointsByType[stats.generation],
      }}
    >
      {children}
    </StatsContext.Provider>
  );
};
