import { useContext } from "react";
import { statCosts } from "./constants";
import { StatsContext } from "./StatsContext";

const StatPicker = ({ name }) => {
  const { stats, setStat, maxStat, minStat } = useContext(StatsContext);
  const score = stats[name];
  const modifier = Math.floor((score - 10) / 2);
  const disableIncrease =
    score >= maxStat || stats.points < statCosts[score + 1] - statCosts[score];

  return (
    <div className={"stat-picker " + name}>
      <div className="stat-picker-label">{name}</div>
      <div className="stat-picker-buttons">
        <button
          disabled={score <= minStat}
          onClick={() => setStat(name, score - 1)}
        >
          -
        </button>
        <div className="circle-value">{score}</div>
        <button
          disabled={disableIncrease}
          onClick={() => setStat(name, score + 1)}
        >
          +
        </button>
      </div>
      <div className="stat-picker-modifier circle-value">
        {modifier > 0 ? `+${modifier}` : modifier}
      </div>
    </div>
  );
};

export default StatPicker;
