export const statNames = ["str", "dex", "con", "int", "wis", "cha"];

export const standardType = "standard";
export const flexibleType = "flexible";
export const minStatsByType = {
  [standardType]: 8,
  [flexibleType]: 3,
};
export const maxStatsByType = {
  [standardType]: 15,
  [flexibleType]: 18,
};
export const startPointsByType = {
  [standardType]: 23,
  [flexibleType]: 28,
};

export const statCosts = {
  3: -23,
  4: -18,
  5: -14,
  6: -10,
  7: -7,
  8: -4,
  9: -2,
  10: 0,
  11: 1,
  12: 3,
  13: 5,
  14: 8,
  15: 11,
  16: 15,
  17: 19,
  18: 24,
};
