import { statNames } from "./constants";
import Points from "./Points";
import StatPickers from "./StatPickers";
import { StatsProvider } from "./StatsContext";
import { useMediaQuery } from "react-responsive";

const App = () => {
  const portrait = useMediaQuery({ maxWidth: 900 });
  const small = useMediaQuery({ maxWidth: 600 });

  return (
    <div className={"app" + (portrait ? " portrait" : "")}>
      <div className="page-body">
        <StatsProvider>
          {small ? (
            <StatPickers statNames={statNames} />
          ) : (
            <>
              <StatPickers statNames={statNames.slice(0, 3)} />
              <StatPickers statNames={statNames.slice(3)} />
            </>
          )}
          <Points />
        </StatsProvider>
      </div>
      <div className="footer">
        <a href="https://ashertuggle.com">
          <img src={process.env.PUBLIC_URL + "/logo192.png"} alt="Logo" />
        </a>
      </div>
    </div>
  );
};

export default App;
