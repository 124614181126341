import { useMediaQuery } from "react-responsive";
import { flexibleType, standardType } from "./constants";
const { useContext } = require("react");
const { StatsContext } = require("./StatsContext");

const Points = () => {
  const { stats, resetStats } = useContext(StatsContext);
  const small = useMediaQuery({ maxWidth: 900 });

  const button = (
    <div className="button-container">
      <button onClick={() => resetStats()}>Reset</button>
    </div>
  );
  const value = <div className="circle-value">{stats.points}</div>;

  let className = "points";
  if (small) {
    className += " small";
  }

  const onGenerationChanged = (event) => resetStats(event.target.value);

  return (
    <div className={className}>
      {small ? (
        <>
          {value}
          {button}
        </>
      ) : (
        <>
          {button}
          {value}
        </>
      )}
      <div className="generation">
        <label title="Limits based on Standard Array generation">
          <input
            type="radio"
            name="limit"
            value={standardType}
            checked={stats.generation === standardType}
            onChange={onGenerationChanged}
          />
          Standard
        </label>
        <label title="Limits based on 4d6 generation">
          <input
            type="radio"
            name="limit"
            value={flexibleType}
            checked={stats.generation === flexibleType}
            onChange={onGenerationChanged}
          />
          Flexible
        </label>
      </div>
    </div>
  );
};

export default Points;
